<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    :dependentLinks="dependentLinks"
    @downloadFile="onDownloadFile"
    @save="onSave('TelemarketingScripts')"
    @cancel="onCancel('TelemarketingScripts')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'TelemarketingScriptEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'TelemarketingScripts',
      pageHeader: 'Редактирование скрипта телемаркетинга',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Скрипты телемаркетинга',
          route: { name: 'TelemarketingScripts' },
        },
        {
          text: 'Редактирование скрипта телемаркетинга',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      telemarketingProducts: (state) => state.common.telemarketingProducts,
    }),

    dependentLinks() {
      return [
        {
          text: 'Вопросы по телемаркетингу',
          route: {
            name: 'TelemarketingQuestionsForScript',
            params: {
              id: this.id,
            },
          },
        },
      ];
    },

    fields() {
      return [
        {
          groups: [
            {
              form: [
                {
                  type: 'select',
                  key: 'productId',
                  label: 'Продукт',
                  list: this.telemarketingProducts.data,
                },
                {
                  type: 'text',
                  key: 'script',
                  label: 'Скрипт',
                },
                {
                  type: 'textarea',
                  key: 'scriptText',
                  label: 'Текст скрипта',
                },
              ],
            },
            {
              groupTitle: 'Коммерческое предложение для отправки на Email',
              form: [
                {
                  type: 'file',
                  key: 'file',
                  label: 'Файл коммерческого предложения',
                },
                {
                  type: 'text',
                  key: 'messageHeader',
                  label: 'Заголовок письма',
                },
                {
                  type: 'textarea',
                  key: 'messageText',
                  label: 'Текст письма',
                },
              ],
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const telemarketingProducts = this.getCommonList({
      name: 'TelemarketingProducts',
    });

    const item = this.getItem({
      name: this.controllerName,
      id: this.id,
    }).then(() => {
      this.form = this.lodash.cloneDeep(this.item.data);
    });

    Promise.all([telemarketingProducts, item]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
      getItem: 'editableList/getItem',
      downloadFile: 'editableList/downloadFile',
      updateItem: 'editableList/updateItem',
    }),

    validateForm(form, mandatoryFields) {
      const emptyField = mandatoryFields.filter((item) => {
        return !form[item.key];
      });
      let message = null;
      if (emptyField.length) {
        message = `Не заполнены обязательные поля: ${emptyField
          .map((item) => item.label)
          .join(', ')}`;
      }
      return message;
    },

    onDownloadFile({ key }) {
      this.downloadFile({
        name: this.controllerName,
        id: this.id,
        type: key,
      });
    },

    onSave(route) {
      const mandatoryFields = [
        { key: 'productId', label: 'Продукт' },
        { key: 'script', label: 'Скрипт' },
        { key: 'scriptText', label: 'Текст скрипта' },
        { key: 'messageHeader', label: 'Заголовок письма' },
        { key: 'messageText', label: 'Текст письма' },
      ];

      const header = this.validateForm(this.form, mandatoryFields);
      if (header) {
        this.$notify({
          header,
          type: 'error',
          timer: 5000,
        });
        return;
      }

      this.isLoadingPage = true;

      this.updateItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.$router.push({ name: route });
          }
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
  },
};
</script>
